import type { NextPage } from 'next';
import Head from 'next/head';
import { ErrorPage404 } from '~/components/community/pages/ErrorPage404';

const index: NextPage = ({}) => {
  return (
    <>
      <Head>
        <title>404 | Internet Game</title>
        <meta property='og:title' content='404 | Internet Game' key='title' />
        <meta
          name='description'
          content='We invent new ways for people to play together to make the internet more fun.'
        />
        <meta
          name='og:description'
          content='We invent new ways for people to play together to make the internet more fun.'
        />
      </Head>

      <ErrorPage404 />
    </>
  );
};

export default index;
