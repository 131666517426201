import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import React from 'react';
import { PrimaryButton } from '~/components/common/Button';

interface ErrorPage404Props {}

export const ErrorPage404: React.FC<ErrorPage404Props> = ({}) => {
  const router = useRouter();

  return (
    <div className='relative h-screen w-screen flex justify-center items-center bg-night6 space-grotesk-regular overflow-hidden'>
      {/* Gradients */}
      <div className='absolute inset-x-0 -top-1 flex justify-center pointer-events-none'>
        <div className='relative w-full h-[34rem] blur-sm'>
          <Image
            src='/assets/community/gradients/dashboardTop.webp'
            layout='fill'
            alt='Gradient'
            priority={true}
          />
        </div>
      </div>
      <div className='absolute inset-x-0 bottom-0 flex justify-center pointer-events-none z-0'>
        <div className='relative w-full h-[24rem] blur-smd'>
          <Image
            src='/assets/community/gradients/dashboardBottom.webp'
            layout='fill'
            alt='Gradient'
            priority={true}
          />
        </div>
      </div>

      {/* Content */}
      <div className='flex flex-col items-center space-y-24'>
        <div className='flex items-end space-x-8'>
          <div className='relative text-9xl text-white space-grotesk-bold'>404</div>
          <div className='text-4xl text-white'>| NOT FOUND</div>
        </div>
        <PrimaryButton onClick={() => router.push('/dashboard')} text='Back Home' />
      </div>
    </div>
  );
};
